<script>
import { formatDate, createDate } from '/~/utils/format/date'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'cart-payment-failed',
  components: {
    BaseIcon,
    BaseButton,
    BaseAsidePage,
  },
  setup() {
    return {
      formatDate,
      createDate,
    }
  },
}
</script>

<template>
  <base-aside-page title="Pyament failed" class="h-full" no-padding>
    <div class="flex h-full flex-col">
      <div class="grow overflow-auto p-5">
        <div class="mt-5 flex justify-center">
          <div
            class="flex h-20 w-20 shrink-0 items-center justify-center rounded-full bg-red-700 text-center text-white"
          >
            <base-icon svg="v2/heroic/x" :size="56" />
          </div>
        </div>
        <div class="mt-5 text-center text-3xl font-bold text-eonx-neutral-800">
          Payment Failed
        </div>
        <div
          class="mt-2.5 text-center font-bold leading-relaxed text-eonx-neutral-600"
        >
          {{ formatDate('daymonthyeartime', createDate()) }}
        </div>
        <div class="mt-5 text-center text-eonx-neutral-600">
          <div v-for="(error, index) in $route.query.errors" :key="index">
            {{ error }}
          </div>
          <div class="mt-5">
            {{ $route.query.message }}
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="mt-auto px-5 py-6">
        <div class="flex w-full space-x-6">
          <base-button class="flex-1" @click="$router.back()">
            Go Back
          </base-button>
        </div>
      </div>
    </template>
  </base-aside-page>
</template>
